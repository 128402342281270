/* eslint-disable prefer-regex-literals */
import globalMessages from 'components/globalMessages'
import { DFNS_DATE_FORMAT, DFNS_WEEK_FORMAT, DFNS_HOUR_FORMAT, SHORT_TIME_FORMAT, CUSTOM_RANGE, DFNS_LONG_DATE_FORMAT } from 'constants/index'
import {
  parseISO,
  differenceInHours,
  addHours,
  differenceInDays,
  addDays,
  differenceInWeeks,
  addWeeks,
  startOfDay,
  endOfDay,
  format,
  startOfISOWeek,
  endOfISOWeek,
  parse,
  addMinutes,
  isSameDay,
  addYears,
  getDay,
  closestTo,
  startOfWeek,
  formatISO,
  formatRelative as dfnsFormatRelative,
  eachWeekOfInterval,
  eachDayOfInterval,
  isBefore,
  isAfter,
  isValid,
  setHours
} from 'date-fns'
import de from 'date-fns/locale/de'
import { head, isNaN, last, uniq } from 'lodash'
import { getRangePreset, shouldFilterWeekdays } from 'utils'

/**
 * Returns a zero-filled xAxis with all hours, days or weeks in the given range
 * @param {*} groupBy The grouping for the axis
 * @param {*} range The given date range
 * @param {*} weekdays The weekdays for the axis
 **/
export const getDateTimeAxis = (groupBy, range, weekdays, initNull) => {
  const cData = {}
  const points = []

  const pushPoints = (b, e) => {
    let bDate = startOfDay(parseISO(b))
    let eDate = endOfDay(parseISO(e))
    let current = new Date(bDate)

    switch (groupBy) {
      case 'hour':
        points.push(format(bDate, DFNS_HOUR_FORMAT))

        while (differenceInHours(eDate, current) > 0) {
          current = addHours(current, 1)
          points.push(format(current, DFNS_HOUR_FORMAT))
        }
        break
      case 'date':
        if (weekdays) {
          if (weekdays.includes(current.getDay().toString())) {
            points.push(format(bDate, DFNS_DATE_FORMAT))
          }
        } else {
          points.push(format(bDate, DFNS_DATE_FORMAT))
        }

        while (differenceInDays(eDate, current) > 0) {
          current = addDays(current, 1)
          if (weekdays) {
            if (weekdays.includes(current.getDay().toString())) {
              points.push(format(current, DFNS_DATE_FORMAT))
            }
          } else {
            points.push(format(current, DFNS_DATE_FORMAT))
          }
        }
        break
      case 'week':
        bDate = startOfISOWeek(bDate)
        eDate = endOfISOWeek(eDate)

        if (b === e) {
          // if we have a single date, we just use the week of the date
          points.push(format(bDate, DFNS_WEEK_FORMAT))
          break
        }

        // when the start of our week is before the start of our range, we do have
        // a partial week at the beginning, which would be missleading in the chart
        // In that case we skip the first week for the diagram
        if (!isBefore(bDate, startOfDay(parseISO(b)))) {
          points.push(format(bDate, DFNS_WEEK_FORMAT))
        }

        while (differenceInWeeks(eDate, current) > 0) {
          current = addWeeks(current, 1)
          if (!isAfter(current, endOfDay(parseISO(e)))) {
            points.push(format(current, DFNS_WEEK_FORMAT))
          }
        }
        break
    }
  }

  let realRange = range
  if (typeof range === 'object' && !Array.isArray(range)) {
    realRange = calculateFloatingRange(range, true)
  }

  if (!realRange || realRange.length === 0) { return cData }

  if (realRange.length !== 2) {
    realRange.forEach(r => {
      pushPoints(r, r)
    })
  } else {
    pushPoints(realRange[0], realRange[1])
  }

  uniq(points).forEach(p => { cData[p] = initNull ? null : 0 })
  return cData
}

export const getDateTimeAxisNew = (groupBy, range, hasExactDaysInRange, initNull) => {
  const cData = {}
  const points = []
  const weekdays = null

  const pushPoints = (b, e) => {
    let bDate = startOfDay(parseISO(b))
    let eDate = endOfDay(parseISO(e))
    let current = new Date(bDate)

    switch (groupBy) {
      case 'hour':
        points.push(format(bDate, DFNS_HOUR_FORMAT))

        while (differenceInHours(eDate, current) > 0) {
          current = addHours(current, 1)
          points.push(format(current, DFNS_HOUR_FORMAT))
        }
        break
      case 'date':
        if (weekdays) {
          if (weekdays.includes(current.getDay().toString())) {
            points.push(format(bDate, DFNS_DATE_FORMAT))
          }
        } else {
          points.push(format(bDate, DFNS_DATE_FORMAT))
        }

        while (differenceInDays(eDate, current) > 0) {
          current = addDays(current, 1)
          if (weekdays) {
            if (weekdays.includes(current.getDay().toString())) {
              points.push(format(current, DFNS_DATE_FORMAT))
            }
          } else {
            points.push(format(current, DFNS_DATE_FORMAT))
          }
        }
        break
      case 'week':
        bDate = startOfISOWeek(bDate)
        eDate = endOfISOWeek(eDate)

        if (b === e) {
          // if we have a single date, we just use the week of the date
          points.push(format(bDate, DFNS_WEEK_FORMAT))
          break
        }

        // when the start of our week is before the start of our range, we do have
        // a partial week at the beginning, which would be missleading in the chart
        // In that case we skip the first week for the diagram
        if (!isBefore(bDate, startOfDay(parseISO(b)))) {
          points.push(format(bDate, DFNS_WEEK_FORMAT))
        }

        while (differenceInWeeks(eDate, current) > 0) {
          current = addWeeks(current, 1)
          if (!isAfter(current, endOfDay(parseISO(e)))) {
            points.push(format(current, DFNS_WEEK_FORMAT))
          }
        }
        break
    }
  }

  if (!range || range.length === 0) {
    return cData
  }

  if (hasExactDaysInRange) {
    range.forEach(r => {
      pushPoints(r, r)
    })
  } else {
    pushPoints(range[0], range[1])
  }

  uniq(points).forEach(p => { cData[p] = initNull ? null : 0 })
  return cData
}

export const parseDate = (dateString) => parseISO(dateString)
export const formatISODate = (date) => formatISO(date || new Date(), { representation: 'date' })
export const formatLocalized = (date, formatString = 'P', intl) => {
  if (!isValid(date)) { return intl.formatMessage(globalMessages.invalidDate) }
  return format(date, formatString, { locale: intl.locale === 'de-DE' ? de : undefined, weekStartsOn: 1 })
}
export const formatRelative = (date, intl) => dfnsFormatRelative(date, new Date(), { locale: intl.locale === 'de-DE' ? de : undefined, weekStartsOn: 1 })
export const formatLocalizedRange = (range, intl) => {
  const r = [typeof (range[0]) === 'string' ? parseISO(range[0]) : range[0], typeof (range[1]) === 'string' ? parseISO(range[1]) : range[1]]
  return isSameDay(r[0], r[1])
    ? formatLocalized(r[0], DFNS_LONG_DATE_FORMAT, intl)
    : r.map((item) => formatLocalized(item, DFNS_LONG_DATE_FORMAT, intl)).join(' - ')
}

export const getWeekdays = (localeString) => {
  let locale
  switch (localeString) {
    case 'de-DE':
      locale = de
      break
    default:
      break
  }

  const days = []
  let d = startOfWeek(new Date())
  for (let i = 0; i <= 6; i++) {
    d = addDays(d, 1)
    days.push({
      index: i,
      key: d.getDay(),
      sortIndex: d.getDay() === 0 ? 7 : d.getDay(),
      string: format(d, 'EEEE').toLowerCase(),
      label: format(d, 'EEEE', { locale }),
      short: format(d, 'EEEEEE', { locale })
    })
  }
  return days
}

export const getCompleteDateRange = (data, weekdays) => {
  const k = Object.keys(data).sort()
  const byWeek = (k[0] || '').includes('W')

  const interval = {
    start: byWeek ? parse(head(k), DFNS_WEEK_FORMAT, new Date()) : parseISO(head(k)),
    end: byWeek ? parse(last(k), DFNS_WEEK_FORMAT, new Date()) : parseISO(last(k))
  }

  const dates = byWeek
    ? eachWeekOfInterval(interval, { weekStartsOn: 1 }).map(v => v.valueOf())
    : shouldFilterWeekdays(weekdays)
      ? eachDayOfInterval(interval).filter(d => weekdays.includes(d.getDay().toString())).map(v => v.valueOf())
      : eachDayOfInterval(interval).map(v => v.valueOf())

  return {
    dates,
    byWeek
  }
}

export const getDateRangeLimitedToWeekdays = (range, weekdays) => {
  const dates = eachDayOfInterval({
    start: parseISO(range[0]),
    end: parseISO(range[1])
  })
  return dates.filter(d => weekdays.includes(d.getDay().toString())).map(d => formatISODate(d))
}

export const createIntervalledTimeValues = (interval = 15) => {
  const startDate = startOfDay(new Date('2020-01-01T00:00:00.000Z'))
  let currentDate = startDate

  const slots = [format(currentDate, SHORT_TIME_FORMAT)]

  while (isSameDay(startDate, currentDate)) {
    currentDate = addMinutes(currentDate, interval)
    const timeValue = format(currentDate, SHORT_TIME_FORMAT)

    if (timeValue === '00:00') {
      continue
    }

    slots.push(timeValue)
  }

  return slots
}

/**
 * Returns the previous years date range and preserves the weekdays if necessary.
 * @param {The start of the date range} from
 * @param {The end of the date range} to
 * @returns The new date range in the previous year
 */
export const getPreviousDateRange = (dateFilter) => {
  if (dateFilter.operator === 'in') {
    // if specific dates are given, we're having a weekday filter, so we always want to preserve the weekday

    return {
      ...dateFilter,
      value: dateFilter.value.map(d => getWeekdayInPreviousYear(parseISO(d)))
    }
  }
  if (dateFilter.operator === 'range') {
    const from = dateFilter.value[0]
    const to = dateFilter.value[1]
    const fromDate = typeof (from) === 'string' ? parseISO(from) : from
    const toDate = typeof (to) === 'string' ? parseISO(to) : to

    if (isNaN(fromDate.getTime())) { throw new Error(`Error parsing ${from} with ${DFNS_DATE_FORMAT}.`) }
    if (isNaN(toDate.getTime())) { throw new Error(`Error parsing ${to} with ${DFNS_DATE_FORMAT}.`) }

    const diffDays = differenceInDays(toDate, fromDate)
    const startDate = getWeekdayInPreviousYear(fromDate)

    const newRange = [
      startDate,
      format(addDays(startDate, diffDays), DFNS_DATE_FORMAT)
    ]

    return {
      field: 'date',
      operator: 'range',
      value: newRange
    }
  }
  return dateFilter
}

/**
 * Gets the date in the previous year but keeps the weekday and returns the closest same weekday to the given date
 * @param {The original date string} dateStr
 * @returns The nearest date to the original date which is the same weekday
 */
export const getWeekdayInPreviousYear = (originDate, asDate) => {
  const previousYearDate = addYears(originDate, -1)
  const diff = getDay(originDate) - getDay(previousYearDate)

  // Get the same weekday in previous year
  const date1 = addDays(previousYearDate, diff)

  // Take the same day in next and previous week
  const date2 = addWeeks(date1, 1)
  const date3 = addWeeks(date1, -1)

  // Determine the closest weekday to our calculated previous year date
  const closest = closestTo(previousYearDate, [date1, date2, date3])

  if (asDate) return closest
  return format(closest, DFNS_DATE_FORMAT)
}

export const getRangeForPreset = (rangeOption, range) => {
  if (!rangeOption || rangeOption === CUSTOM_RANGE) { // if we have a custom or no range defined, we use it or a non-dangerous default
    const today = formatISO(new Date(), { representation: 'date' })
    return !range || range === 0 ? [today, today] : range
  } else { // if we have a non-custom rangeOption, we ignore the range parameter
    return getRangePreset(rangeOption, true)
  }
}

export const getDateValueFromString = (dateString) => {
  if (dateString.includes('W')) {
    return parse(dateString.split(' - ')[0], DFNS_WEEK_FORMAT, new Date()).valueOf()
  } else if (dateString.includes('T')) {
    // date is a string like 2023-05-08T06
    const parts = dateString.split('T')
    const d = parseISO(parts[0])
    const hour = parseInt(parts[1])
    return setHours(d, hour).valueOf()
  } else {
    return parseISO(dateString).valueOf()
  }
}

const calculateDate = (now, unit, value) => {
  switch (unit) {
    case 'daysPast':
      return addDays(now, -value)
    case 'daysFuture':
      return addDays(now, value)
    case 'weeksPast':
      return addWeeks(now, -value)
    case 'weeksFuture':
      return addWeeks(now, value)
  }
}

export const calculateFloatingRange = (floatingRangeValue, isoFormat, single) => {
  if (!floatingRangeValue) return null
  const now = new Date()
  const from = calculateDate(now, floatingRangeValue.from.unit, floatingRangeValue.from.value)
  const to = calculateDate(now, floatingRangeValue.to.unit, floatingRangeValue.to.value)

  if (!isValid(from) || !(isValid(to) || single)) return null
  if (isoFormat) {
    return [formatISODate(from), single ? formatISODate(from) : formatISODate(to)]
  }
  return [from, single ? from : to]
}

export const getWeekDayArray = (intl) => {
  const weekdays = []
  const start = startOfWeek(Date(), { weekStartsOn: 1 })
  for (let i = 0; i < 7; i++) {
    const day = new Date(start)
    day.setDate(start.getDate() + i)
    const formattedDay = formatLocalized(day, 'EEEEE', intl)
    weekdays.push(formattedDay)
  }
  return weekdays
}
