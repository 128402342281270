import Heading from 'components/Heading'
import { Calendar, Text } from 'components/Primitives'
import { ProIcon } from 'components/ProIcon'
import { TextSelect } from 'components/TextSelect'
import labelMessages from 'components/labelMessages'
import { useEffect, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { FloatingPeriodPicker } from './FloatingPeriodPicker'
import messages from 'components/Pickers/messages'
import { CUSTOM_RANGE, FLOATING_RANGE } from 'constants/index'
import { calculateFloatingRange, getPreviousDateRange, getWeekdayInPreviousYear } from 'utils/datetime'
import { addDays, addMonths, addQuarters, addYears, differenceInCalendarDays, endOfISOWeek, endOfMonth, endOfQuarter, getISOWeek, min as minDate, setISOWeek, startOfISOWeek } from 'date-fns'
import { RenderCount } from 'components/RenderCount'
import { RangePreview } from 'components/RangePreview'
import { getRangePreset } from 'utils'
import globalMessages from 'components/globalMessages'
import rangePickerMessages from 'components/RangePicker/messages'

export const OPTION_VALUES = {
  previousYear: 'previousYear',
  previousPeriod: 'previousPeriod',
  custom: 'custom',
  floating: 'floating'
}

const getOptionLabel = (option, preset, intl, days) => {
  if (option === OPTION_VALUES.previousYear) return intl.formatMessage(messages.previousYear)
  if (option === OPTION_VALUES.previousPeriod) {
    switch (preset) {
      case 'today':
        return intl.formatMessage(globalMessages.yesterday)
      case 'yesterday':
        return intl.formatMessage(messages.previousNDays, { days: 1 })
      case 'tomorrow':
        return intl.formatMessage(globalMessages.today)
      case 'thisWeek':
      case 'weekToDate':
        return intl.formatMessage(rangePickerMessages.lastWeek)
      case 'lastWeek':
        return intl.formatMessage(messages.previousWeek)
      case 'nextWeek':
        return intl.formatMessage(rangePickerMessages.thisWeek)
      case 'thisMonth':
      case 'monthToDate':
        return intl.formatMessage(rangePickerMessages.lastMonth)
      case 'lastMonth':
        return intl.formatMessage(messages.previousMonth)
      case 'nextMonth':
        return intl.formatMessage(rangePickerMessages.thisMonth)
      case 'thisQuarter':
      case 'quarterToDate':
        return intl.formatMessage(rangePickerMessages.lastQuarter)
      case 'lastQuarter':
        return intl.formatMessage(messages.previousQuarter)
      case 'nextQuarter':
        return intl.formatMessage(rangePickerMessages.thisQuarter)
      case 'thisYear':
      case 'yearToDate':
        return intl.formatMessage(rangePickerMessages.lastYear)
      case 'lastYear':
        return intl.formatMessage(messages.previousYear)
      case CUSTOM_RANGE:
      case FLOATING_RANGE:
      default:
        return intl.formatMessage(messages.previousNDays, { days })
    }
  }
  if (option === OPTION_VALUES.custom) {
    return intl.formatMessage(messages.specificStartDate)
  }
  if (option === OPTION_VALUES.floating) {
    return intl.formatMessage(messages.floatingStartDate)
  }
}

const getSettingLabel = (preset) => {
  switch (preset) {
    default:
    case 'today':
    case 'yesterday':
    case 'tomorrow':
    case 'thisYear':
    case 'yearToDate':
    case 'lastYear':
    case 'nextYear':
    case CUSTOM_RANGE:
    case FLOATING_RANGE:
      return labelMessages.date
    case 'thisWeek':
    case 'weekToDate':
    case 'lastWeek':
    case 'nextWeek':
      return messages.isoWeek
    case 'thisMonth':
    case 'monthToDate':
    case 'lastMonth':
    case 'nextMonth':
      return messages.month
    case 'thisQuarter':
    case 'quarterToDate':
    case 'lastQuarter':
    case 'nextQuarter':
      return messages.quarter
  }
}

const getRangeLength = (range) => {
  if (!range || !range.length) return 0
  return differenceInCalendarDays(range[1], range[0]) + 1
}

const YearPresets = ['thisYear', 'yearToDate', 'lastYear']

export const ComparingPeriodPicker = ({ selectedPreset, selectedRange, floatingRangeValue, selectedComparisonOption, selectedComparisonRange, selectedPrevYSetting, selectedComparisonFloatingValue, setSelectedComparisonOption, setSelectedComparisonRange, setSelectedPrevYSetting, setSelectedComparisonFloatingValue, onTextSelectOpenListener }) => {
  const intl = useIntl()
  const targetRange = useMemo(() => selectedPreset === FLOATING_RANGE && floatingRangeValue
    ? calculateFloatingRange(floatingRangeValue)
    : selectedRange
  , [selectedRange, selectedPreset, floatingRangeValue])

  const rangeLenght = getRangeLength(targetRange)
  const maxStart = addDays(targetRange[0], -rangeLenght)

  const handleChange = (key, value) => {
    if (selectedPreset === FLOATING_RANGE && !floatingRangeValue) return
    const newOpt = YearPresets.includes(selectedPreset) && (selectedComparisonOption === OPTION_VALUES.previousPeriod || value === OPTION_VALUES.previousPeriod)
      ? OPTION_VALUES.previousYear
      : ((key == 'option' ? value : selectedComparisonOption) || OPTION_VALUES.previousYear)
    const newSetting = (key == 'setting' ? value : selectedPrevYSetting) || 'proximity'
    const newStart = minDate([
      maxStart,
      (key == 'start'
        ? value
        : (selectedComparisonRange
            ? selectedComparisonRange[0]
            : null)) ||
      getWeekdayInPreviousYear(getRangePreset('today', true)[0], true)
    ])
    const newFloating = key == 'floating'
      ? value
      : selectedComparisonFloatingValue

    let newRange
    let s, w, y
    switch (newOpt) {
      default:
      case OPTION_VALUES.previousYear:
        if (newSetting === 'proximity') {
          newRange = getPreviousDateRange({ operator: 'range', value: targetRange }).value
        } else if (newSetting === 'date') {
          switch (selectedPreset) {
            default:
            case 'today':
            case 'yesterday':
            case 'tomorrow':
            case 'thisQuarter':
            case 'lastQuarter':
            case 'nextQuarter':
            case 'monthToDate':
            case 'quarterToDate':
            case 'thisYear':
            case 'yearToDate':
            case 'lastYear':
            case CUSTOM_RANGE:
            case FLOATING_RANGE:
              newRange = targetRange.map((d) => addYears(d, -1))
              break
            case 'thisWeek':
            case 'lastWeek':
            case 'nextWeek':
            case 'weekToDate':
              // Catching cases where week 1 or 53 are partly on the other year
              w = getISOWeek(targetRange[0])
              if (w === 53) y = addYears(targetRange[0], -1)
              else y = addYears(targetRange[1], -1)
              s = startOfISOWeek(setISOWeek(y, w))
              newRange = [s, selectedPreset === 'weekToDate' ? addDays(s, rangeLenght - 1) : endOfISOWeek(s)]
              break
            case 'thisMonth':
            case 'lastMonth':
            case 'nextMonth':
              s = addYears(targetRange[0], -1)
              newRange = [s, endOfMonth(s)]
              break
          }
        }
        break
      case OPTION_VALUES.previousPeriod:
        switch (selectedPreset) {
          default:
          case 'today':
          case 'yesterday':
          case 'tomorrow':
          case 'thisWeek':
          case 'lastWeek':
          case 'nextWeek':
          case CUSTOM_RANGE:
          case FLOATING_RANGE:
            newRange = [addDays(targetRange[0], -rangeLenght), addDays(targetRange[1], -rangeLenght)]
            break
          case 'thisMonth':
          case 'lastMonth':
          case 'nextMonth':
            s = addMonths(targetRange[0], -1)
            newRange = [s, endOfMonth(s)]
            break
          case 'thisQuarter':
          case 'lastQuarter':
          case 'nextQuarter':
            s = addQuarters(targetRange[0], -1)
            newRange = [s, endOfQuarter(s)]
            break
          case 'weekToDate':
            newRange = [addDays(targetRange[0], -7), addDays(targetRange[1], -7)]
            break
          case 'monthToDate':
            s = addMonths(targetRange[0], -1)
            newRange = [s, addDays(s, rangeLenght - 1)]
            break
          case 'quarterToDate':
            s = addQuarters(targetRange[0], -1)
            newRange = [s, addDays(s, rangeLenght - 1)]
            break
        }
        break
      case OPTION_VALUES.custom:
        newRange = [newStart, addDays(newStart, rangeLenght - 1)]
        break
      case OPTION_VALUES.floating:
        s = calculateFloatingRange(newFloating, false, true)
        newRange = s ? [s[0], addDays(s[0], rangeLenght - 1)] : []
        break
    }

    setSelectedComparisonOption(newOpt)
    setSelectedPrevYSetting(newSetting)
    setSelectedComparisonRange(newRange.map((d) => new Date(d)))
    setSelectedComparisonFloatingValue(newFloating)
  }

  useEffect(() => {
    handleChange()
  }, [targetRange, selectedPreset, floatingRangeValue])

  const options = useMemo(() => {
    return [
      { value: OPTION_VALUES.previousYear, label: getOptionLabel(OPTION_VALUES.previousYear, selectedPreset, intl) },
      ...(!YearPresets.includes(selectedPreset) ? [{ value: OPTION_VALUES.previousPeriod, label: getOptionLabel(OPTION_VALUES.previousPeriod, selectedPreset, intl, rangeLenght) }] : []),
      { value: OPTION_VALUES.custom, label: getOptionLabel(OPTION_VALUES.custom, selectedPreset, intl) },
      { value: OPTION_VALUES.floating, label: getOptionLabel(OPTION_VALUES.floating, selectedPreset, intl) }
    ]
  }, [selectedPreset, rangeLenght, selectedPreset])

  return (
    <>
      <RenderCount />
      <Heading type='h5' className='my-4'>
        {intl.formatMessage(labelMessages.comparisonPeriod)}
        <ProIcon />
      </Heading>
      <TextSelect
        className='w-full'
        value={selectedComparisonOption}
        onChange={(v) => handleChange('option', v)}
        creatable={false}
        allowClear={false}
        required
        options={options}
        onOpenListener={onTextSelectOpenListener}
      />
      {selectedComparisonOption === OPTION_VALUES.previousYear && (
        <>
          <Text className='mt-4' title={intl.formatMessage(messages.matchBy)} color='black'>{intl.formatMessage(messages.matchBy)}</Text>
          <TextSelect
            className='w-full'
            value={selectedPrevYSetting}
            onChange={(v) => handleChange('setting', v)}
            creatable={false}
            allowClear={false}
            required
            options={[
              { value: 'proximity', label: intl.formatMessage(messages.matchingWeekdays) },
              { value: 'date', label: intl.formatMessage(getSettingLabel(selectedPreset)) }
            ]}
            onOpenListener={onTextSelectOpenListener}
          />
        </>
      )}
      {selectedComparisonOption === OPTION_VALUES.custom && <Calendar className='mt-4' onChange={(v) => handleChange('start', v)} value={selectedComparisonRange[0]} maxValue={maxStart} />}
      {selectedComparisonOption === OPTION_VALUES.floating && <FloatingPeriodPicker className='mt-4' onChange={(v) => handleChange('floating', v)} value={selectedComparisonFloatingValue} maxDate={maxStart} fixedRangeLength={rangeLenght} invalidRangeMessage={<FormattedMessage {...globalMessages.invalidComparingPeriod} />} />}
      {selectedComparisonOption !== OPTION_VALUES.floating && <RangePreview className='mt-4' range={selectedComparisonRange} />}
    </>
  )
}
