import './AntForm.scss'

import { BoldLabel, FormField } from '.'
import DatePicker from 'antd/lib/date-picker' // We have to import it like this to make sure we have the patched version
import { Button } from 'components/Button'
import { DEFAULT_DATE_FORMAT, WEEK_FORMAT } from 'constants/index'
import {
  DateRangePicker,
  DateWithTimePicker,
  TimePicker,
  FoodwasteCategoryPicker,
  LocationPicker,
  LocationResponsiblePicker,
  TimeRangePicker,
  UserPicker,
  SearchSelectTagPicker,
  CustomerPicker,
  ItemPicker,
  UserSearchPicker
} from 'components/Pickers'
import { FieldArray, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { omit, uniqueId } from 'lodash'

import CheckRules from '../CheckRules'
import { ColorPicker } from 'components/Pickers/ColorPicker'
import Heading from 'components/Heading'
import MobileFriendlyModal from '../MobileFriendlyModal'
import { NumericFormat } from 'react-number-format'
import { SearchSelect } from 'components/SearchSelect'
import Steps from '../Steps'
import classNames from 'classnames'
import globalMessages from '../globalMessages'
import { FormattedMessage, useIntl } from 'react-intl'
import { usePrevious } from 'ahooks'
import { useRole } from 'hooks'
import { TextSelect } from 'components/TextSelect'
import { ChangedFieldsSummary } from 'components/ChangedFieldsSummary'
import { TagManagement } from 'components/TagManagement'
import { ErrorIndicator } from './ErrorIndicator'
import labelMessages from 'components/labelMessages'
import { getWeekdays } from 'utils/datetime'
import { Input } from 'components/Primitives'

const Field = ({ def, popupContainer, errors, intl, values }) => {
  const omitProps = ['type', 'roles', 'variant', 'visible', 'checkRules', 'badge', 'props', 'withHoliday', 'useStringKey']
  switch (def.type) {
    case 'email':
      return (
        <FormField
          as={Input}
          inputType='input'
          {...omit(def, omitProps)}
        />
      )
    case 'password':
      return (
        <FormField
          as={Input}
          inputType='input'
          type='password'
          {...omit(def, omitProps)}
        />
      )
    case 'boolean':
      const booleanOptions = [
        ...def.nullable ? [{ value: 'null', label: intl.formatMessage(globalMessages.nullOption) }] : [],
        { value: 'true', label: intl.formatMessage(globalMessages.yes) },
        { value: 'false', label: intl.formatMessage(globalMessages.no) }
      ]
      return (
        <FormField
          as={TextSelect}
          inputType={def.type}
          required
          allowClear={false}
          creatable={false}
          isSearchable={false}
          options={booleanOptions}
          {...omit(def, omitProps)}
        />
      )
    case 'number-format':
      return (
        <FormField
          as={NumericFormat}
          inputType='number-format'
          customInput={Input}
          wrapperClassName={def.badge ? `badge-${def.badge}` : undefined}
          {...omit(def, omitProps)}
        />
      )
    case 'text-select':
      return (
        <FormField
          as={TextSelect}
          menuPortalTarget={document.body}
          {...omit(def, omitProps)}
        />
      )
    case 'search-select':
      return (
        <FormField
          as={SearchSelect}
          single={def.variant === 'single'}
          inputType='search-select'
          withPortal={false}
          fitWidth
          {...omit(def, omitProps)}
        />
      )
    case 'location':
      return (
        <FormField
          as={LocationPicker}
          inputType={def.type}
          variant={def.variant}
          withPortal={false}
          fitWidth
          {...omit(def, omitProps)}
        />
      )
    case 'search-tag':
      return (
        <FormField
          as={SearchSelectTagPicker}
          inputType={def.type}
          variant={def.variant}
          withPortal={false}
          {...omit(def, omitProps)}
        />
      )
    case 'user':
      return (
        <FormField
          as={UserPicker}
          inputType={def.type}
          variant={def.variant}
          withPortal={false}
          {...omit(def, omitProps)}
        />
      )
    case 'user-search':
      return (
        <FormField
          as={UserSearchPicker}
          menuPortalTarget={document.body}
          {...omit(def, omitProps)}
        />
      )
    case 'item':
      return (
        <FormField
          alwaysOn
          as={ItemPicker}
          inputType={def.type}
          variant={def.variant}
          isFormField
          withPortal={false}
          {...omit(def, omitProps)}
        />
      )
    case 'customer':
      return (
        <FormField
          as={CustomerPicker}
          inputType={def.type}
          variant={def.variant}
          withPortal={false}
          {...omit(def, omitProps)}
        />
      )
    case 'multi-location-responsibles':
      return (
        <FieldArray
          name={def.name}
          render={arrayHelpers => (
            <LocationResponsiblePicker
              arrayHelpers={arrayHelpers}
            />
          )}
        />
      )
    case 'date':
      return (
        <FormField
          as={def.variant === 'month' ? DatePicker.MonthPicker : def.variant === 'week' ? DatePicker.WeekPicker : DatePicker}
          format={def.variant === 'month' ? undefined : def.variant === 'week' ? WEEK_FORMAT : DEFAULT_DATE_FORMAT}
          inputType={def.type}
          showToday={false}
          {...omit(def, omitProps)}
          className={classNames('w-full', def.className)}
          {...popupContainer ? { getCalendarContainer: () => popupContainer } : {}}
        />
      )
    case 'daterange':
      return (
        <DateRangePicker
          {...omit(def, omitProps)}
          className={classNames('w-full h-11', def.className)}
          {...popupContainer ? { getCalendarContainer: () => popupContainer } : {}}
        />
      )
    case 'datetime':
      return (
        <FormField
          as={DateWithTimePicker}
          inputType={def.type}
          className={classNames('w-full', def.className)}
          {...omit(def, omitProps)}
          {...popupContainer ? { getPopupContainer: () => popupContainer } : {}}
        />
      )
    case 'weekday':
      const weekdays = getWeekdays(intl.locale)
      const weekdayOptions = weekdays.map(wd => ({
        value: def.useMainIndex
          ? wd.index
          : def.useStringKey ? wd.string : wd.sortIndex,
        label: wd.label
      }))
      if (def.withHoliday) {
        weekdayOptions.unshift({
          value: def.useStringKey ? 'public_holiday' : -1,
          label: intl.formatMessage(labelMessages.publicHoliday)
        })
      }
      return (
        <FormField
          as={TextSelect}
          isMulti={def.variant === 'multi'}
          allowClear={def.variant !== 'multi'}
          creatable={false}
          options={weekdayOptions}
          {...omit(def, omitProps)}
        />
      )
    case 'time':
      return (
        <FormField
          as={TimePicker}
          {...omit(def, omitProps)}
          className={classNames('w-full', def.className)}
        />
      )
    case 'timerange':
      return (
        <FormField
          as={TimeRangePicker}
          inputType={def.type}
          {...omit(def, omitProps)}
        />
      )
    case 'foodwaste-category':
      return (
        <FoodwasteCategoryPicker
          {...omit(def, omitProps)}
          className={classNames('w-full', def.className)}
        />
      )
    case 'text':
      return (
        <FormField
          as={Input}
          inputType='input'
          {...omit(def, omitProps)}
        />
      )
    case 'password-rules':
      return <CheckRules {...omit(def, omitProps)} />
    case 'header':
      return <BoldLabel className={classNames('my-2', def.className)} tagName='p'>{def.label}</BoldLabel>
    case 'infotext':
      return (
        <span className={classNames({
          'text-gray-light': def.variant === 'help',
          'text-primary-lighter font-bold': def.variant === 'primary'
        }, def.className)}
        >
          {def.label}
        </span>
      )
    case 'error-indicator':
      return <ErrorIndicator errors={errors} {...omit(def, omitProps)} />
    case 'switch':
      return <BoldLabel className='my-2 text-feedback-error' tagName='p'>Not implemented: {def.type} ({def.label})</BoldLabel>
    // return (
    //   <FormField
    //     as={Toggle}
    //     {...omit(def, omitProps)}
    //   />
    // )
    case 'button':
      return (
        <Button onClick={def.onClick} className='my-2' fullWidth type={def.buttonType}>
          {def.label}
        </Button>
      )
    case 'color':
      return (
        <FormField
          as={ColorPicker}
          inputType='color'
          {...omit(def, omitProps)}
        />
      )
    case 'component':
      if (def.asChild) {
        return def.render({ popupContainer, values })
      }
      return (
        <FormField
          as={def.render}
          inputType='component'
          popupContainer={popupContainer}
          forceValue={values}
          {...omit(def, omitProps)}
        />
      )
    case 'tags':
      return (
        <FormField
          as={TagManagement}
          tagType='component'
          {...omit(def, omitProps)}
        />
      )

    case 'None':
      return null
    default:
      return <BoldLabel className='my-2 text-feedback-error' tagName='p'>Fehlende Definition: {def.type} ({def.label})</BoldLabel>
  }
}

const isVisible = (field, values) => {
  if (field.visible == null) return true
  if (typeof (field.visible) === 'boolean') return field.visible
  if (typeof (field.visible) === 'function') return field.visible(values)
}

const getClassName = (field, values) => {
  if (field.className == null) return undefined
  if (typeof (field.className) === 'string') return field.className
  if (typeof (field.className) === 'function') return field.className(values)
}

const getPlaceholder = (field, values) => {
  if (field.placeholder == null) return undefined
  if (typeof (field.placeholder) === 'string') return field.placeholder
  if (typeof (field.placeholder) === 'function') return field.placeholder(values)
}

const getProps = (field, values) => {
  if (field.props == null) return undefined
  if (typeof (field.props) === 'function') return field.props(values)
}

const getAllowReset = (values, key) => (values[key] !== undefined)

const getBulkEditClassName = (values, key) => {
  if (values[key] !== undefined) return 'changedValue'

  return undefined
}

const getBulkEditPlaceholder = (intl, values, key) => {
  if (values[key] === undefined) { return intl.formatMessage(globalMessages.keepExistingValue) }
  if (values[key] === null) { return intl.formatMessage(globalMessages.valueDeleted) }

  return undefined
}

export const GenericForm = ({
  bulkEditing,
  stepper,
  asModal,
  title,
  visible,
  structure,
  initialValues,
  validationSchema,
  onCancel,
  onSubmit,
  okText,
  cancelText,
  confirmLoading,
  resetOnCancel,
  size,
  okDisabled,
  bulkEditConfirmMessage,
  hidden,
  reinitialize,
  className,
  bulkEditingCount,
  confirmationQuestion,
  loading,
  captureRender,
  proPreview
}) => {
  const intl = useIntl()
  const [uniqueKey, setUniqueKey] = useState(uniqueId('form'))
  const { isPermitted } = useRole()
  const [renderModal, setRenderModal] = useState(visible)
  const [showConfirmation, setShowConfirmation] = useState(false)

  // if the form was set to visible, we create a new unique key enforcing a brand new formik using the new initial values
  const previousVisible = usePrevious(visible)
  useEffect(() => {
    if (visible) {
      setRenderModal(visible)
      setShowConfirmation(false)
    }
    if (!previousVisible && visible) {
      setUniqueKey(uniqueId('form'))
    } else {
      setTimeout(() => {
        setRenderModal(false)
        setShowConfirmation(false)
      }, 300)
    }
  }, [visible])

  const renderLoading = () => (
    <div className='my-6 text-center text-muted'>
      <FormattedMessage {...globalMessages.pleaseWait} />
    </div>
  )

  const renderFields = (values, setFieldValue, popupContainer, errors) => {
    return structure.map(section => {
      if (!isPermitted(section.roles) || !isVisible(section, values)) {
        section.fields.forEach(field => {
          if (values[field.name]) setFieldValue(field.name, undefined)
        })
        return null
      }
      return (
        <div className={classNames('mb-4', stepper ? 'mt-6' : null)} key={section.title}>
          {section.title && <Heading type='h5' className='mb-3'>{section.title}</Heading>}
          {section.fields.map(field => {
            if (!isPermitted(field.roles) || !isVisible(field, values)) {
              if (values[field.name]) setFieldValue(field.name, undefined)
              return null
            }
            const cls = bulkEditing ? getBulkEditClassName(values, field.name) : undefined
            const placeholder = bulkEditing ? getBulkEditPlaceholder(intl, values, field.name) : undefined
            const allowReset = bulkEditing ? getAllowReset(values, field.name) : undefined

            return (
              <div key={`${field.type}_${field.name}`} className={field.noMargin ? null : 'mb-4'}>
                <Field
                  popupContainer={popupContainer}
                  intl={intl}
                  def={{
                    ...field,
                    className: classNames(getClassName(field, values), cls),
                    placeholder: getPlaceholder(field, values) || placeholder,
                    ...getProps(field, values),
                    ...(['password-rules'].includes(field.type) ? { value: values[field.name] } : undefined)
                  }}
                  {...(field.passValues ? { values } : undefined)}
                  {...(field.type === 'error-indicator' ? { errors } : undefined)}
                />
                {allowReset && (
                  <div className='mt-2 text-sm text-gray-light'>
                    <FormattedMessage
                      id='phrase.valueWillBeChanged'
                      defaultMessage='The value is adjusted according to your change in the field. Reset the value if you do not want to change it.'
                      tagName='span'
                    />
                    <Button tabIndex={-1} size='s' type='tertiary' onClick={() => setFieldValue(field.name, undefined)} data-action='reset-change'>
                      →&nbsp;<FormattedMessage {...globalMessages.reset} />
                    </Button>
                  </div>
                )}
              </div>
            )
          })}
        </div>
      )
    })
  }

  // FIXME: if the form isn't rendered as modal, we don't have submit and cancel buttons currently.
  const renderChildren = (props) => {
    const { handleSubmit, values, resetForm, setFieldValue, errors } = props
    if (captureRender) {
      captureRender(props)
    }

    const onSubmit = () => {
      if (bulkEditing) {
        if (showConfirmation) {
          handleSubmit()
        } else {
          setShowConfirmation(true)
        }
      } else {
        handleSubmit()
      }
    }

    /* This is a hack to prevent submitting the form if the user has selected a tag that is not yet saved. */
    // FIXME: If we have a form with multiple tag components with different names, this will not work and we should
    // use the structure to find out, which values we have to check
    let preventSubmit = false
    if (values.tags) {
      preventSubmit = values.tags.some(tag => tag.id === -1)
    }

    if (asModal) {
      if (showConfirmation) {
        return (
          <MobileFriendlyModal
            className={className}
            hidden={hidden}
            visible={visible}
            title={title}
            onPrimary={onSubmit}
            onCancel={() => setShowConfirmation(false)}
            size={size || 's'}
            footer={{
              cancelText: intl.formatMessage(globalMessages.back),
              primaryLoading: confirmLoading
            }}
            confirm
          >
            {bulkEditConfirmMessage}
            <FormattedMessage
              {...(confirmationQuestion || {
                id: 'phrase.bulkEditConfirmation',
                defaultMessage: 'Are you sure you want to adjust the following fields for <b>{amount, plural, one {# entry} other {# entries}}</b>?'
              })}
              values={{ amount: bulkEditingCount, b: chunks => <b>{chunks}</b> }}
            />
            <ChangedFieldsSummary values={values} structure={structure} />
            <FormattedMessage {...globalMessages.noUndo} />
          </MobileFriendlyModal>
        )
      } else {
        return (
          <MobileFriendlyModal
            className={className}
            hidden={hidden}
            visible={visible}
            title={title}
            onPrimary={onSubmit}
            proPreview={proPreview}
            onCancel={(e) => {
              // FIXME: this can't really work
              if (e != null && resetOnCancel && e.currentTarget.getAttribute('class') === 'ant-btn') {
                resetForm()
              } else { onCancel(e) }
            }}
            size={size || 's'}
            footer={{
              primaryText: bulkEditing && !showConfirmation ? intl.formatMessage(globalMessages.continue) : okText,
              cancelText,
              primaryLoading: confirmLoading,
              primaryDisabled: preventSubmit || okDisabled || (bulkEditing && Object.keys(values).length === 0)
            }}
            bodyStyle={stepper ? { position: 'relative' } : undefined}
          >
            {(popupContainer) => (
              <>
                {stepper && <Steps steps={stepper.steps} current={stepper.current} />}
                {loading ? renderLoading() : renderFields(values, setFieldValue, popupContainer, errors)}
              </>
            )}
          </MobileFriendlyModal>
        )
      }
    }
    return renderFields()
  }

  return (
    <Formik
      key={uniqueKey}
      initialValues={initialValues}
      enableReinitialize={reinitialize}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        if (onSubmit) { onSubmit(values) } else { console.warn('Tried to handle submit but no onSubmit callback for GenericFormwas provided') }
      }}
    >
      {renderModal ? renderChildren : null}
    </Formik>
  )
}
