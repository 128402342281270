import { create } from 'zustand'

export const useDebugStore = create((set) => ({
  messages: [],
  visible: false,
  debugMode: false,
  debugChunkError: false,
  setDebugMode: (debugMode) => set(() => ({ debugMode })),
  toggle: () => set((state) => ({ visible: !state.visible })),
  logDebugMessage: (message) => set((state) => {
    let str
    try {
      str = typeof (message) === 'string' ? message : JSON.stringify(message, null, 2)
    } catch (ex) {
      str = `[ERROR SERIALIZING: ${ex.message}]`
    }
    return ({
      messages: [...state.messages, {
        time: new Date().toISOString(),
        message: str
      }]
    })
  }),
  setDebugChunkError: (debugChunkError) => set(() => ({ debugChunkError }))
}))
