import { cloneDeep, find, kebabCase, omit, pick, some } from 'lodash'
import messages from './messages'
import { getDefaultSizesByReportType, getMinSizesByReportType } from 'components/Report/utils'
import { useDashboardStore } from 'hooks/store/useDashboardStore'
import { DASHBOARD_DEFAULT_FILTERS } from 'constants/defaultSettings'

export const NO_ACCESS = 'noAccess'
export const VIEWER = 'viewer'
export const EDITOR = 'editor'
export const OWNER = 'owner'

export const getDashboardTitle = (dashboard, intl) => dashboard.title || intl.formatMessage(messages.defaultDashboard)

export const addReport = (reportEntity, forceLayout) => {
  const { id, definition } = reportEntity
  const { breakpoint, currentDashboard } = useDashboardStore.getState()
  const def = cloneDeep(definition)

  const bp = breakpoint || 'lg'

  const minSizes = getMinSizesByReportType(def.type)

  const l = {
    i: definition.id,
    ...(forceLayout
      ? {
          ...pick(forceLayout, ['x', 'y']),
          w: minSizes.minW > forceLayout.w ? minSizes.minW : forceLayout.w,
          h: minSizes.minH > forceLayout.h ? minSizes.minH : forceLayout.h,
          ...minSizes
        }
      : {
          x: 0,
          // we are placing new reports at the bottom of the dashboard
          y: currentDashboard.layouts[bp].length === 0 ? 0 : Math.max(...currentDashboard.layouts[bp].map((item) => item.y)),
          ...(def.dimensions ? def.dimensions : getDefaultSizesByReportType(def.type)),
          ...(getMinSizesByReportType(def.type))
        }
    )
  }

  const newLayouts = {}
  Object.keys(currentDashboard.layouts).forEach((key) => {
    newLayouts[key] = [l, ...currentDashboard.layouts[key]]
  })

  return {
    dashboardId: currentDashboard.id,
    layouts: newLayouts,
    reports: [...currentDashboard.reports, { id, definition: def }]
  }
}

export const minifyLayouts = (layouts) => {
  // for each key in layouts, we map the value to get an array with only the relevant attributes
  const minifiedLayout = {}
  Object.keys(layouts).forEach((key) => {
    minifiedLayout[key] = layouts[key].map((item) => pick(item, ['i', 'x', 'y', 'w', 'h', 'minW', 'minH']))
  })
  return minifiedLayout
}

export const getOwnAccess = (user, access, extendedAccessUsers) => {
  const ownAccess = extendedAccessUsers
    ? find(access, (a) => a.user?.id === user.id)?.type
    : find(access, (a) => a.user === user.id)?.type
  const tagAccess = find(access, (a) => user.tags?.includes(a.user_tag))?.type
  const generalAccess = find(access, { user: null, user_tag: null })?.type
  const arr = [ownAccess, tagAccess, generalAccess]

  if (arr.includes(OWNER)) return OWNER
  if (arr.includes(EDITOR)) return EDITOR
  if (arr.includes(VIEWER)) return VIEWER
  return NO_ACCESS
}

export const getReportByDefinition = (definition, dashboard) => find(dashboard.reports, (r) => r.definition.id === definition.id)

export const getDashboardIdsInOrder = (dashboards, orderIds) => {
  const ids = orderIds || []
  const dashboardsOrdered = (ids).filter(id => dashboards.find(d => d.id === id))
  if (ids.length !== dashboards.length) {
    dashboardsOrdered.push(...dashboards.filter(d => !ids.includes(d.id)).map(d => d.id))
  }
  return dashboardsOrdered
}

export const extendDashboard = (user, dashboard) => {
  const oDashboard = omit(dashboard, ['path', 'isOwner', 'isShared', 'isPrivate', 'ownAccessType'])
  // check if dashboard.access.user is an object or a number
  const extendedAccessUsers = some(dashboard.access, (d) => d.user != null && typeof d.user === 'object')
  const isShared = extendedAccessUsers
    ? find(dashboard.access, (d) => d.user?.id !== user.id) != null
    : find(dashboard.access, (d) => d.user !== user.id) != null
  const isOwner = extendedAccessUsers
    ? find(dashboard.access, (a) => a.user?.id === user.id && a.type === OWNER) != null
    : find(dashboard.access, (a) => a.user === user.id && a.type === OWNER) != null
  return {
    path: kebabCase(`${dashboard.id}-${dashboard.title}` || 'null'),
    isOwner,
    isShared,
    isPrivate: !isShared,
    ownAccessType: getOwnAccess(user, dashboard.access, extendedAccessUsers),
    ...oDashboard,
    ...((dashboard.layouts !== undefined)
      ? {
          layouts: {
            lg: [],
            md: [],
            xxs: [],
            ...dashboard.layouts
          }
        }
      : undefined),
    ...((dashboard.filters !== undefined)
      ? {
          filters: {
            ...DASHBOARD_DEFAULT_FILTERS,
            ...dashboard.filters
          }
        }
      : undefined)
  }
}
