import { SearchSelect } from 'components/SearchSelect'
import { useIntl } from 'react-intl'
import { getWeekdays } from 'utils/datetime'
import labelMessages from 'components/labelMessages'

export default ({ value, onChange, flat, medium, placeholder, className, required, single, useStringKey, useSortIndex, withHoliday, gray, withPortal, type, disabled }) => {
  const intl = useIntl()

  const holidayOption = {
    key: useStringKey ? 'public_holiday' : -1,
    label: intl.formatMessage(labelMessages.publicHoliday)
  }
  const weekdays = getWeekdays(intl.locale)

  const options = [
    ...withHoliday ? [holidayOption] : [],
    ...weekdays.map(wd => ({
      key: useStringKey ? wd.string : (useSortIndex ? wd.sortIndex : wd.key),
      label: wd.label
    }))
  ]

  return (
    <SearchSelect
      role='weekday-picker'
      className={className}
      type={type || (flat ? undefined : 'round')}
      size={medium ? undefined : 'small'}
      placeholder={placeholder || intl.formatMessage(single ? labelMessages.weekday : labelMessages.weekdays)}
      value={value}
      onChange={onChange}
      required={required}
      single={single}
      optionsLabel={intl.formatMessage(labelMessages.weekdays)}
      options={options}
      graybg={gray}
      withPortal={withPortal}
      disabled={disabled}
    />
  )
}
